<template>
    <div class="page_content">
        <div class="page_edit_content">
            <Form
                ref="form"
                @handleChange="handleChange"
                @handleSubmit="handleSubmit"
                :form_data="form_data"
            >
			
                <template slot="rule_user">
                    <TransferTree
                        @change="TransferTreeChange"
                        :treeData.sync="treeData"
                        :value.sync="treeDataValue"
                    />
                    <a-input hidden="true" v-decorator="['rule_user']"></a-input>
                </template>
          
            </Form>
        </div>
    </div>
</template>
<script>
import Form from "@/components/Form";
import { saveAuth, authEdit, authInfo,getRoleList,saveUser } from '@/api/system'
import TransferTree from "@/components/TransferTree";
import { post_user } from '@/api/api'

export default {
    name: 'AuthEdit',
    components: {
        Form,
        TransferTree
    },
    data() {
        return {
            query: {},
            treeData: [],
            treeDataValue: [],
            form_data: {
                title: '添加',
                show_submit_btn: true,
                show_close_btn: true,
                list: [
                    {
                        type: "slot",
                        name: "rule_user",
                        title: "选择人员",
                        options: {},
                    },
                ]
            }
        }
    },
    created() {
        this.query = this.$route.query;
		// this.$method.get_department().then(res => {
		//     this.form_data.list = this.$method.set_form_list(this.form_data.list,  'treeData', res)
		// });
		
        post_user().then(res => {
            this.treeData = this.$method.get_user_department_list(res.data.list)
            getRoleList().then(res => {
                // this.treeData = this.$method.get_user_department_list(res.data.list)
                res.data.rule_user.map(io => {
                    this.treeDataValue.push("user_" + io.id)
                });
            })
        })
		// getRoleList().then(res => {
		//     // this.treeData = this.$method.get_user_department_list(res.data.list);
        //     this.treeData = this.$method.get_user_department_list(res.data.list)
		//     res.data.rule_user.map(io => {
		//         this.treeDataValue.push("user_" + io.id)
		//     });
		// })
    },
    methods: {
		
        handleChange(data) {
            let name = "data_auth";
            let { e } = data;
            if (e.target.name == name) {
                data = this.$method.get_form_relation_name(
                    e.target,
                    this.form_data.list,
                    name
                );
                data.relation_name.close = e.target.value !== 2;
            }
        },
        tree_load(keys, info) {
            
        },
        TransferTreeChange(value) {
            this.$refs.form.setFieldsValue({
                rule_user: value.join(',')
            });
        },
        handleSubmit(e) {
            let data = e.values;
            saveUser({
                data: {
                    ...data
                },
                info: true,
            }).then(res => {
                this.visible = false;
                this.$router.go(1);
            })
        }
    }
};
</script>
<style lang="less">
</style>
