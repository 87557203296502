import req from "../methods/req"
import method from '../methods/method'

// 后台菜单配置
export const menuList = function (data) {
	return req({
		url: '/manage/crm.system/menu',
		...data
	})
}

export const saveMenu = function (data) {
	return req({
		url: '/manage/crm.system/saveMenu',
		...data
	})
}
export const delMenu = function (data) {
	return req({
		url: '/manage/crm.system/delMenu',
		...data
	})
}

export const AppMenuList = function (data) {
	return req({
		url: '/manage/crm.system/AppMenuList',
		...data
	})
}

export const saveAppMenu = function (data) {
	return req({
		url: '/manage/crm.system/saveAppMenu',
		...data
	})
}

export const delAppMenu = function (data) {
	return req({
		url: '/manage/crm.system/delAppMenu',
		...data
	})
}

export const appFunctionMenu = function (data) {
	return method.get_list({
		url: '/manage/crm.system/appFunctionMenu',
		...data
	})
}

export const saveAppFunctionMenu = function (data) {
	return req({
		url: '/manage/crm.system/saveAppFunctionMenu',
		...data
	})
}

export const delAppFunctionMenu = function (data) {
	return req({
		url: '/manage/crm.system/delAppFunctionMenu',
		...data
	})
}


export const authList = function (data) {
	return method.get_list({
		url: '/manage/crm.system/authList',
		...data
	})
}

//manage/crm.feedback/getRole 

//app留言板 manage/crm.feedback/saveUser
export const getRoleList = function (data) {
	return req({
		url: '/manage/crm.feedback/getRole ',
		...data
	})
}
export const saveUser = function (data) {
	return req({
		url: '/manage/crm.feedback/saveUser ',
		...data
	})
}
export const authInfo = function (data) {
	return req({
		url: '/manage/crm.system/authInfo',
		...data
	})
}

export const saveAuth = function (data) {
	return req({
		url: '/manage/crm.system/saveAuth',
		...data
	})
}

export const deleteAuth = function (data) {
	return req({
		url: '/manage/crm.system/deleteAuth',
		...data
	})
}
export const authEdit = function (data) {
	return req({
		url: '/manage/crm.system/authEdit',
		...data
	})
}



